import React, { useContext, useEffect, useState, useMemo, useCallback } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Drawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { Link, Outlet } from "react-router-dom";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import { useTranslation } from 'react-i18next';
import { get, findIndex, startsWith } from 'lodash';
import CommonContext from 'features/context/commonContext';
import Badge from '@mui/material/Badge';
import InstallIcon from '@mui/icons-material/AddToHomeScreenTwoTone';
import SocialIcon from '@mui/icons-material/TagTwoTone';
import Announcements from 'features/announcements/Announcements';
import BalanceBar from './BalanceBar';
import BankReminderDialog from './BankReminderDialog';
import PwaPrompt from 'features/pwaPrompt/PassivePwaPrompt';
import { isInWebApp } from 'utils/pwa';
import ExtraNavigation from './ExtraNavigation';
import { useCheckAppVersion } from 'hooks/useCheckAppVersion';

// icons
import HomeIcon from '@mui/icons-material/HomeTwoTone';
import WalletIcon from '@mui/icons-material/LocalAtmTwoTone';
import GamesIcon from '@mui/icons-material/GamesTwoTone';
import RewardIcon from '@mui/icons-material/RedeemTwoTone';
import FreeIcon from '@mui/icons-material/FavoriteTwoTone';
import AccountIcon from '@mui/icons-material/AccountCircleTwoTone';
import SettingsIcon from '@mui/icons-material/TuneTwoTone';
import CallIcon from '@mui/icons-material/SupportAgentTwoTone';
import LogoutIcon from '@mui/icons-material/LogoutTwoTone';

// logout
import { useAuth } from 'hooks/useAuth';
import { useLocation, useNavigate } from "react-router-dom";

// user config
import { useUserConfig } from 'hooks/useUserConfig';

const Main = styled('main', {
  shouldForwardProp: (prop) => {
    return (prop !== 'compactAppBar' && prop !== 'needExtraBottomPadding')
  }
})(
  ({ theme, compactAppBar, needExtraBottomPadding }) => {
    const isMediumSize = useMediaQuery('(min-width:600px)');
    return {
      flexGrow: 1,
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      ...(
        compactAppBar ?
        {
          paddingTop: theme.spacing(isMediumSize ? 7 : 6),
        } :
        {
          paddingTop: theme.spacing(isMediumSize ? 14 : 13),
        }
      ),
      paddingBottom: theme.spacing(needExtraBottomPadding ? 16 : 9),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      })
    };
  },
);

const AppBar = styled(MuiAppBar, {
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  })
}));

const FlexBottomNavigation = styled(Paper, {
  //shouldForwardProp: (prop) => prop !== 'open'
})(({ theme }) => ({
  zIndex: 1100,
  position: 'fixed',
  bottom: 0,
  left: '50%',
  transform: 'translate(-50%, 0%)',
  width: '100%',
/*  '& .MuiBottomNavigation-root': {
    overflowX: 'auto',
    scrollbarWidth: 'none',
    '::-webkit-scrollbar': {
      width: 0,
      height: 0
    }
  },*/
  '& .MuiButtonBase-root.Mui-selected': {
    fontWeight: 700,
  },
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  })
}));

export default function Layout(props) {
  const { logout } = useAuth();
  const { hideAnnouncement, compactAppBar } = useUserConfig();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setGlobalErrorMessage } = useGlobalMessageActionsContext();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [selectedTab, setSelectedTab] = useState(-1);
  const [drawerState, setDrawerState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const { companySetting, hasEligibleLuckyDraw } = useContext(CommonContext);
  const [ openPwa, setOpenPwa ] = useState(false);
  const [ pwaReady, setPwaReady ] = useState(false);
  const { version = '' } = useCheckAppVersion() || {};

  const isInstallable = useMemo(
    () => {
      if (isInWebApp || !pwaReady) return false;
      return true;
    }, [pwaReady]
  );

  const botNavs = useMemo(
    () => {
      return [
        { label: t('Home'), icon: <HomeIcon />, linkTo: '/' },
        { label: t('Wallet'), icon: <WalletIcon />, linkTo: '/wallet' },
        { label: t('Games'), icon: <GamesIcon />, linkTo: '/games' },
        { label: t('Redeem'), icon: <RewardIcon />, linkTo: '/redeem' },
        {
          label: t('Free'),
          icon: hasEligibleLuckyDraw ? <Badge variant='dot' color="error"><FreeIcon /></Badge> : <FreeIcon />,
          linkTo: '/free'
        },
      ];
    }, [t, hasEligibleLuckyDraw]
  );

  const pathName = useMemo(
    () => {
      return get(location, 'pathname');
    }, [location]
  );

  const needExtraBottomPadding = useMemo(
    () => {
      return pathName === '/' || pathName.startsWith('/news/');
    }, [pathName]
  );

  const hasSocialChannels = useMemo(() => {
    const { socialChannels } = companySetting || {};
    return socialChannels && (socialChannels?.facebookLink || socialChannels?.telegramLink);
  }, [companySetting]);

  useEffect(() => {
    const find = findIndex(botNavs, function(nav) {
      if (nav.linkTo === '/') {
        return pathName === nav.linkTo;
      }
      return startsWith(pathName, nav?.linkTo);
    });
    setSelectedTab(find);
  }, [botNavs, pathName]);

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerState({ ...drawerState, [anchor]: open });
  };

  const handleItemClicked = useCallback(
    (link) => (event) => {
      if (event) event.preventDefault();
      navigate(link);
    }, [navigate]
  );

  function handlePwaDialogOpen(event) {
    event?.preventDefault();
    setOpenPwa(true);
  }

  function handlePwaDialogClose(event) {
    event?.preventDefault();
    setOpenPwa(false);
  }

  function handlePwaDialogReady(isReady) {
    setPwaReady(isReady);
  }

  async function handleLogout(event) {
    event.preventDefault();
    try {
      await logout();
    } catch (err) {
      setGlobalErrorMessage({ err });
    } finally {
      navigate('/');
    }
  }

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem button onClick={handleItemClicked('account')}>
          <ListItemIcon>
            <AccountIcon />
          </ListItemIcon>
          <ListItemText primary={t('Account')} />
        </ListItem>
        <ListItem button onClick={handleItemClicked('settings')}>
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText primary={t('Settings')} />
        </ListItem>
        <ListItem button onClick={handleItemClicked('contact')}>
          <ListItemIcon>
            <CallIcon />
          </ListItemIcon>
          <ListItemText primary={t('Contact')} />
        </ListItem>
        {
          hasSocialChannels && (
            <ListItem button onClick={handleItemClicked('social-channels')}>
              <ListItemIcon>
                <SocialIcon />
              </ListItemIcon>
              <ListItemText primary={t('Social Channels')} />
            </ListItem>
          )
        }
        <Divider sx={{ my: 1 }} />
        {
          !!isInstallable &&
          <ListItem button onClick={handlePwaDialogOpen}>
            <ListItemIcon>
              <Badge color="error" variant="dot">
                <InstallIcon />
              </Badge>
            </ListItemIcon>
            <ListItemText primary={t('Add To Home Screen')} />
          </ListItem>
        }
        <ListItem button onClick={handleLogout}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary={t('Logout')} />
        </ListItem>
        {
          !!version && (
            <Box sx={{ textAlign: 'right', mx: 1 }}>
              <Typography variant='caption' color={'text.secondary'} sx={{ fontStyle: 'italic' }}>
                {`v${version}`}
              </Typography>
            </Box>
          )
        }
      </List>
    </Box>
  );

  return (
    <Container component="main" sx={{ p: 0 }}>
      <PwaPrompt onReady={handlePwaDialogReady} open={openPwa} onClose={handlePwaDialogClose} />
      <BankReminderDialog />
      <Box sx={{ display: 'flex' }}>
        <AppBar id='appBar' color='primary' position="fixed">
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={isSmallScreen ? toggleDrawer('top', true) : toggleDrawer('left', true)}
              edge="start"
              sx={{ mr: 2 }}
            >
              <Badge color="error" variant="dot" invisible={!isInstallable}>
                <MenuIcon />
              </Badge>
            </IconButton>
            <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'right', alignItems: 'center' }}>
              <ExtraNavigation onItemClicked={handleItemClicked} />
            </Box>
          </Toolbar>
        </AppBar>
        {['left', 'right', 'top', 'bottom'].map((anchor) => (
          <React.Fragment key={anchor}>
            <Drawer
              anchor={anchor}
              open={drawerState[anchor]}
              onClose={toggleDrawer(anchor, false)}
            >
              {list(anchor)}
            </Drawer>
          </React.Fragment>
        ))}
        {
          !compactAppBar && <BalanceBar />
        }
        <Main compactAppBar={compactAppBar} needExtraBottomPadding={needExtraBottomPadding}>
          <Box sx={{ my: 2 }}>
          {
            !hideAnnouncement && <Announcements />
          }
          </Box>
          <Outlet />
        </Main>
      </Box>
      <FlexBottomNavigation id='bottomNav'>
        <BottomNavigation showLabels={false} value={selectedTab}>
          {
            botNavs.map((nav) => {
              return <BottomNavigationAction key={nav.linkTo} label={nav.label} icon={nav.icon} component={Link} to={nav.linkTo} />
            })
          }
        </BottomNavigation>
      </FlexBottomNavigation>
    </Container>
  );
}
