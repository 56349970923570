import React, { useContext, useMemo, useCallback } from 'react';
import { _CASH_SYMBOL } from 'utils/symbol';
import PointReward from './PointReward';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Decimal from 'decimal.js';
import { get, set } from 'lodash';
import CommonContext from 'features/context/commonContext';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import dayjs from 'dayjs';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import InputAdornment from '@mui/material/InputAdornment';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from "react-router-dom";
import { useUserConfig } from 'hooks/useUserConfig';
import isBetween from 'dayjs/plugin/isBetween';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/en';
import 'dayjs/locale/ms';
import 'dayjs/locale/th';
import 'dayjs/locale/zh';
dayjs.extend(relativeTime);
dayjs.extend(isBetween);

export default function AmountSelector(props) {
  const { userData, userDataReady, companySetting, companySettingReady } = useContext(CommonContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { skipDepositReward, syncSkipDepositReward } = useUserConfig();

  const companySkipDepositReward = useMemo(
    () => {
      return get(companySetting, 'walletConfig.skipDepositReward', false);
    }, [companySetting]
  );

  const savedSkipDepositReward = useMemo(
    () => {
      if (skipDepositReward === null) return companySkipDepositReward;
      return skipDepositReward;
    }, [companySkipDepositReward, skipDepositReward]
  );

  const firstTimeDeposit = useMemo(
    () => {
      const recentCashIn = get(userData, 'recentCashIn.minute', []);
      return !recentCashIn.length;
    }, [userData]
  );

  const minDepositLimit = useMemo(
    () => {
      const { minDeposit: { '$numberDecimal': minDeposit = 10 } = {} } = companySetting?.transaction || {};
      return Number(minDeposit);
    }, [companySetting]
  );

  const maxDepositLimit = useMemo(
    () => {
      const { maxDeposit: { '$numberDecimal': maxDeposit = 30000 } = {} } = companySetting?.transaction || {};
      return Number(maxDeposit);
    }, [companySetting]
  );

  const depositDenominations = useMemo(
    () => {
      const depositDenominations = get(companySetting, 'transaction.depositDenominations', []);
      const firstDepositDenominations = get(companySetting, 'transaction.firstDepositDenominations', []);
      const rawDenominations = firstTimeDeposit ? firstDepositDenominations : depositDenominations;

      const denominations = rawDenominations.map(d => {
        const dec = new Decimal(d?.$numberDecimal || 0);
        return dec.toNumber();
      });
      return denominations;
    }, [companySetting, firstTimeDeposit]
  );

  const defaultDepositAmount = useMemo(
    () => {
      const defaultAmount = depositDenominations[0] || 0;
      return defaultAmount.toFixed(2);
    }, [depositDenominations]
  );

  const amountSchema = Yup.object().shape({
    rewardFlag: Yup.bool().required(t("Required")),
    amount: Yup.number().required(t("Required"))
      .min(minDepositLimit, t('Min amount', { amount: minDepositLimit }))
      .max(maxDepositLimit, t('Max amount', { amount: maxDepositLimit }))
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      amount: defaultDepositAmount,
      rewardFlag: !savedSkipDepositReward
    },
    validationSchema: amountSchema,
    onSubmit: async values => {
      const { amount = 0, rewardFlag } = values;

      try {
        await syncSkipDepositReward(!rewardFlag);
      } catch (err) {
        console.error(err);
      } finally {
        navigate(`${amount}/${rewardFlag}`);
      }
    },
  });

  const isReady = useMemo(
    () => {
      return (userDataReady && companySettingReady);
    }, [userDataReady, companySettingReady]
  );

  const decMinAmount = useMemo(
    () => {
      const min = new Decimal(minDepositLimit);
      return min;
    }, [minDepositLimit]
  );

  const decMaxAmount = useMemo(
    () => {
      const max = new Decimal(maxDepositLimit);
      return max;
    }, [maxDepositLimit]
  );

  const decAmount = useMemo(
    () => {
      const ret = new Decimal(get(formik, 'values.amount', '0'));
      return ret;
    }, [formik]
  );

  const handleOnAmountChange = useCallback(
    (event) => {
      event.preventDefault();
      const value = get(event, 'target.value', '');
      const sanitizedValue = value.replace(/\D/g, '');
      const amountRaw = new Decimal(sanitizedValue);
      const amount = amountRaw.dividedBy(100);
      const amountStr = amount.gt(decMaxAmount) ? decMaxAmount.toFixed(2) : amount.toFixed(2);
      formik.setFieldValue('amount', amountStr);
    }, [formik, decMaxAmount]
  );

  const handleAutoAmount = useCallback(
    (value) => (event) => {
      event?.preventDefault();
      const decValue = new Decimal(value);
      const checkedValue = decValue.lt(decMinAmount) ? decMinAmount.toFixed(2) : decValue.toFixed(2);
      set(event, 'target.value', checkedValue);
      handleOnAmountChange(event);
    }, [handleOnAmountChange, decMinAmount]
  );

  const rewardFlag = useMemo(
    () => {
      return get(formik, 'values.rewardFlag', false);
    }, [formik]
  );

  const handleRewardChanged = (event) => {
    const val = get(event, 'target.checked', false);
    formik.setFieldValue('rewardFlag', val);
  };

  if (!isReady) {
    return (
      <Paper sx={{ p: 3, my: 1 }} elevation={2}>
        <CircularProgress />
      </Paper>
    );
  }

  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Card elevation={4} component="form" onSubmit={formik.handleSubmit}>
            <CardHeader title={t('Deposit')} />
            <Divider />
            <CardContent>
              <Box>
                <Grid spacing={1} container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Grid item xs={12}>
                    <Box>
                      <TextField
                        fullWidth
                        inputProps={{ inputMode: 'numeric' }}
                        inputMode='numeric'
                        id="amount"
                        name="amount"
                        label={t('Amount')}
                        value={formik.values.amount}
                        onBlur={formik.handleBlur}
                        onChange={handleOnAmountChange}
                        error={formik.touched.amount && Boolean(formik.errors.amount)}
                        helperText={formik.touched.amount && formik.errors.amount}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position='start'>
                            {_CASH_SYMBOL}
                            </InputAdornment>
                          )
                        }}
                      />
                    </Box>
                  </Grid>
                {
                  depositDenominations.map(label => {
                    return (
                      <Grid item xs={3} key={`amt-${label}`}>
                        <Button fullWidth onClick={handleAutoAmount(label)} color='info' variant='contained' size='small'>{label}</Button>
                      </Grid>
                    )
                  })
                }
                </Grid>
              </Box>
              <Box sx={{ pt: 2, pl: 2 }}>
                <FormGroup>
                  {
                    companySkipDepositReward ? (
                      <FormControlLabel
                        sx={{
                          '& .MuiFormControlLabel-label': {
                            fontSize: '0.9rem',
                          }
                        }}
                        control={<Checkbox onChange={handleRewardChanged} checked={get(formik, 'values.rewardFlag', true)} />}
                        label={t('Deposit Reward Strict Agreement')}
                      />
                    ) : (
                      <FormControlLabel
                        control={<Checkbox onChange={handleRewardChanged} checked={get(formik, 'values.rewardFlag', true)} />}
                        label={t('Deposit Reward Agreement')}
                      />
                    )

                  }
                </FormGroup>
              </Box>
            </CardContent>
            <Divider />
            <CardActions sx={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
              <Button type="submit" variant='contained'>{t('Next')}</Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <PointReward amount={decAmount.toNumber()} skipReward={!rewardFlag} />
        </Grid>
      </Grid>
    </Box>
  );
}